import create from "zustand";

interface ReportSearchState {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
}

export const useReportSearchStore = create<ReportSearchState>((set) => ({
  startDate: null,
  setStartDate: (date) => set({ startDate: date }),
  tags: [],
  setTags: (tag) => set({ tags: tag }),
}));
