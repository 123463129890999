import { QueryKey } from "@tanstack/react-query";

export const QueryKeys = {
  POLYGON_GALLERY: (polygonId?: string, isDeleted?: boolean) => [
    "polygonGallery",
    polygonId,
    isDeleted,
  ],
  POLYGON_GALLERY_ALL: () => ["polygonGalleryAll"],
  POLYGON_PHOTO: () => ["polygonPhoto"],
  ALL_POLYGON_PHOTO_IN_A_FLOOR: (
    floorId: string,
    activeFloor: string,
    page: number,
    process?: string
  ) => ["allPolygonPhotoInAFloor", activeFloor, floorId, page, process],
  ALL_POLYGON_PHOTO_IN_ALL_FLOOR: (
    activeFloor: string,
    page: number,
    process?: string
  ) => ["allPolygonPhotoInAllFloor", activeFloor, page, process],
  A_POLYGON_GALLERY: (
    activeFloor: string,
    polygon_id: string,
    page: number,
    process?: string
  ) => ["aPolygonGallery", activeFloor, polygon_id, page, process],
  POLYGON_DATA: (floorId: string | undefined) => ["polygonData", floorId],
  DASHBOARD_SCHEDULE_TABLE: () => ["scheduleTable"],
  WORK_LOG: (date: any) => ["workLog", date],
  DASHBOARD_CONSTRUCTION: () => ["constructionData"],
  REPORT_DETAIL: (id?: string) => ["reportDetail", id],
  REPORTS: (
    constrId: string | null,
    page: number,
    selectedCategory: string | null,
    search: string
  ) => ["reports", constrId, page, selectedCategory, search],
  DRAWING_INFO: () => ["drawingInfo"] as QueryKey,
  DRAWING_IMAGE: (drawingId: string | null) => ["drawingImage", drawingId],
  SHOP_DRAWING_IMAGE: (shop_id: string | null) => ["drawingImage", shop_id],
  MATERIAL_LIST: (constrId: string | null, page?: number) => [
    "materialList",
    constrId,
    page,
  ],
  CONSTRUCTIONS: () => ["constructions"],
  A_CONSTRUCTION: () => ["aConstruction"],
  SITELOG_DAYILY_LOG_DATA: (page?: number, title?: string) => [
    "siteLogData",
    page,
    title,
  ],
  BOOKMARK: () => ["bookmark"],
  SITELOG_DAILY_DETAIL: (constrId: string, dailyId: string) => [
    "dailyDetail",
    constrId,
    dailyId,
  ],
  SITELOG_WEATHER: (formattedDate: string) => ["weatherData", formattedDate],
  SITELOG_PERIOD_DAILY: (start: string, end: string) => ["periodDaily", start, end],
  SITELOG_PERIOD_RESOURCE: (start: string, end: string) => ["periodResource", start, end],
  SITELOG_PERIOD_TITLE: () => ["periodTitle"],
  NOTI: (constrId: string) => ["noti", constrId],
};
