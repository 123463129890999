import { useQueryClient } from "@tanstack/react-query";
import i18next from "i18next";
import { useRouter } from "next/navigation";

import { useTranslation } from "@/app/i18n/client";
import { removeCookie, setCookie } from "@/utils/cookie";

export const useHeader = ({ lng }: { lng: string }) => {
  const router = useRouter();
  const userName =
    typeof window !== "undefined" ? (localStorage.getItem("username") ?? "") : "";
  const { t } = useTranslation(lng, "common", {});
  const queryClient = useQueryClient();
  const handleLngOptionClick = (option: string) => {
    i18next.changeLanguage(option);
    removeCookie("i18next");
    setCookie("i18next", option);
    router.push(window.location.href.replace(`/${lng}`, `/${option}`));
  };

  const lngLabel = lng === "ko" ? "한국어" : "English";
  const logoutLabel = lng === "ko" ? "로그아웃" : "Logout";
  const mypageLabel = lng === "ko" ? "마이페이지" : "Mypage";

  const handleLogout = () => {
    localStorage.clear();
    queryClient.removeQueries();
    queryClient.clear();
    router.push("/");
  };

  return {
    userName,
    lngLabel,
    logoutLabel,
    mypageLabel,
    handleLogout,
    handleLngOptionClick,
  };
};
