import { useQuery } from "@tanstack/react-query";

import { NotificationApi } from "@/utils/fetchData";
import { QueryKeys } from "@/utils/queryKeys";

const getNotificationList = async (constr_id: string) => {
  const response = await NotificationApi.getNotify(constr_id);
  return response.data;
};

export const useGetNotificationList = (constr_id: string) => {
  return useQuery({
    queryKey: QueryKeys.NOTI(constr_id),
    queryFn: () => getNotificationList(constr_id),
    staleTime: 1000 * 60 * 60 * 24,
    gcTime: 1000 * 60 * 60 * 24,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    select: (data: any[]) => {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
      const filteredData = data.filter(
        (notif) => new Date(notif.created_at) >= sevenDaysAgo
      );
      return filteredData;
    },
    enabled: !!constr_id,
  });
};
