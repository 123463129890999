import { extendTheme } from "@chakra-ui/react";

import { alertTheme } from "./toastTheme";

const radii = {
  "radius-04": "4px",
  "radius-08": "8px",
  "radius-12": "12px",
  "radius-16": "16px",
  "radius-20": "20px",
  "radius-24": "24px",
  "radius-28": "28px",
  "radius-32": "32px",
  "radius-48": "48px",
  "radius-56": "56px",
  "radius-72": "72px",
  "radius-1000": "1000px",
};

const borderRadius = {
  radii: {
    none: "0",
    sm: "0.125rem",
    4: "4px",
    8: "8px",
    16: "16px",
    base: "0.25rem",
    md: "0.375rem",
    lg: "0.5rem",
    xl: "0.75rem",
    "2xl": "1rem",
    "3xl": "1.5rem",
    full: "9999px",
  },
};

const spacing = {
  space: {
    px: "1px",
    "4": "4px",
    "8": "8px",
    "12": "12px",
    "16": "16px",
    "20": "20px",
    "24": "24px",
    "32": "32px",
    "48": "48px",
    "56": "56px",
    "72": "72px",
  },
};

export const theme = extendTheme({
  ...borderRadius,
  ...spacing,
  colors: {
    WHITE: "#FFFFFF",
    WHITE_BF: "rgba(191, 191, 191, 0.15)",
    BLUE_SUB_0: "#212A4A",
    BLUE_SUB_2: "#393D53",
    BLUE_SUB_3: "#575D7C",
    BLUE_SKY_1: "#DEE1ED",
    BLUE_SKY_2: "#F2F4FC",
    BLUE_MAIN: "#223A88",
    GREY_BLACK: "#1F2539",
    GREY_0: "#73758A",
    GREY_1: "#979BA9",
    GREY_2: "#B3B8C0",
    GREY_3: "#C4CCD6",
    GREY_4: "#DCDFE7",
    GREY_5: "#EAECF1",
    GREY_6: "#EEF1F4",
    blue900: "#212A4A",
    blue800: "#333D60",
    blue700: "#223A88",
    blue600: "#2643A6",
    blue500: "#526BC2",
    blue400: "#6F83C7",
    blue300: "#C8D0EF",
    blue200: "#E7EBF8",
    blue100: "#F2F4FC",
    grey_black: "#1F2539",
    grey_0: "#73758A",
    grey_1: "#979BA9",
    grey_2: "#B3B8C0",
    grey_3: "#C4CCD6",
    grey_4: "#DCDFE7",
    grey_5: "#EAECF1",
    grey_6: "#EEF1F4",
    grey_7: "#F9AFB",
    red: "#FF5356",
  },

  fontSizes: {
    display01: "96px", // 디스플레이 01
    title01: "36px", // 타이틀 01
    title02: "32px", // 타이틀 02
    title03: "28px", // 타이틀 03
    headline01: "24px", // 헤드라인 01
    headline02: "24px", // 헤드라인 02
    body01: "16px", // 바디 01
    body02: "16px", // 바디 02
    label01: "15px", // 레이블 01
    label02: "15px", // 레이블 02
    label03: "14px", // 레이블 03
    label04: "14px", // 레이블 04
    label05: "12px", // 레이블 05
    label06: "12px", // 레이블 06
    label07: "10px", // 레이블 07
    label08: "10px", // 레이블 08
  },

  fontWeights: {
    semi_bold: 600, // semi_bold
    bold: 700, // bold
  },

  textStyles: {
    display01: {
      fontFamily: "Pretendard",
      fontSize: "96px",
      letterSpacing: "-3%",
      lineHeight: "120%",
      fontWeight: "bold",
    },
    title01: {
      fontFamily: "Pretendard",
      fontSize: "36px",
      letterSpacing: "-2%",
      lineHeight: "140%",
      fontWeight: "bold",
    },
    title02: {
      fontFamily: "Pretendard",
      fontSize: "32px",
      letterSpacing: "-2%",
      lineHeight: "140%",
      fontWeight: "bold",
    },
    title03: {
      fontFamily: "Pretendard",
      fontSize: "28px",
      letterSpacing: "-2%",
      lineHeight: "140%",
      fontWeight: "bold",
    },
    headline01: {
      fontFamily: "Pretendard",
      fontSize: "20px",
      letterSpacing: "-2%",
      lineHeight: "140%",
      fontWeight: "semi_bold",
    },
    headline02: {
      fontFamily: "Pretendard",
      fontSize: "20px",
      letterSpacing: "-2%",
      lineHeight: "140%",
      fontWeight: "medium",
    },
    body01: {
      fontFamily: "Pretendard",
      fontSize: "16px",
      letterSpacing: "0%",
      lineHeight: "160%",
      fontWeight: "medium",
    },
    body02: {
      fontFamily: "Pretendard",
      fontSize: "16px",
      letterSpacing: "0%",
      lineHeight: "160%",
      fontWeight: "medium",
    },
    label01: {
      fontFamily: "Pretendard",
      fontSize: "15px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "semi_bold",
    },
    label02: {
      fontFamily: "Pretendard",
      fontSize: "15px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "medium",
    },
    label03: {
      fontFamily: "Pretendard",
      fontSize: "14px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "semi_bold",
    },
    label04: {
      fontFamily: "Pretendard",
      fontSize: "14px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "medium",
    },
    label05: {
      fontFamily: "Pretendard",
      fontSize: "12px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "semi_bold",
    },
    label06: {
      fontFamily: "Pretendard",
      fontSize: "12px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "medium",
    },
    label07: {
      fontFamily: "Pretendard",
      fontSize: "10px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "semi_bold",
    },
    label08: {
      fontFamily: "Pretendard",
      fontSize: "10px",
      letterSpacing: "0%",
      lineHeight: "140%",
      fontWeight: "medium",
    },
  },
  components: {
    Alert: alertTheme,
    Drawer: {
      // Add custom Drawer configuration
      baseStyle: {
        dialog: {
          bg: "WHITE",
          boxShadow: "0px 1px 2px 2px rgba(195, 195, 195, 0.10)",
          borderLeft: "1px solid",
          borderColor: "GREY_5",
        },
        overlay: {
          bg: "rgba(0, 0, 0, 0.2)",
        },
      },
      sizes: {
        custom: {
          dialog: {
            maxW: "224px",
            w: "224px",
            minW: "224px",
          },
        },
      },
      defaultProps: {
        size: "custom",
      },
    },
  },

  styles: {
    global: {
      ":root": {
        "--white": "#FFFFFF",
        "--white-bf": "rgba(191, 191, 191, 0.15)",
        "--blue-sub-0": "#212A4A",
        "--blue-sub-2": "#393D53",
        "--blue-sub-3": "#575D7C",
        "--blue-sky-1": "#DEE1ED",
        "--blue-main": "#223A88",
        "--grey-black": "#1F2539",
        "--grey-0": "#73758A",
        "--grey-1": "#979BA9",
        "--grey-3": "#C4CCD6",
        "--grey-4": "#DCDFE7",
        "--grey-5": "#EAECF1",
        "--grey-6": "#EEF1F4",
      },
      "*": {
        boxSizing: "border-box",
      },
      html: {
        width: "100%",
        height: "100%",
        margin: 0,
        overflowX: "hidden",
        padding: 0,
        fontSize: "16px",
      },
      body: {
        width: "100%",
        height: "100%",
        margin: 0,
        lineHeight: "1.6",
        fontSize: "18px",
        backgroundColor: "#EFF1F2",
        color: "#252525",
      },
      a: {
        color: "#0070f3",
        textDecoration: "none",
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },
});
