"use client";

import { useQueryClient } from "@tanstack/react-query";
import { Header as UpsightHeader } from "@upsightdevs/upsight-design-system";
import { usePathname, useRouter } from "next/navigation";
import React, { useEffect, useRef } from "react";

import { usePostLogout } from "@/api/AuthApi/postLogout";
import { useGetNotificationList } from "@/api/NotificationApi/getNotify";
import { usePostNotify } from "@/api/NotificationApi/postNotify";
import { useTranslation } from "@/app/i18n/client";
import { useHeader } from "@/hooks/useHeader";
import { useReportSearchStore } from "@/store/WriteReport/reportSearchStore";

interface Notification {
  id: string;
  content: string; // "YYYY-MM-DD" 형식
  created_at: string;
  [key: string]: any;
}

const Header = () => {
  const pathname = usePathname();
  const router = useRouter();
  const pathnameArray = pathname.split("/");
  const { t } = useTranslation(pathnameArray[1]);
  const username = (typeof window !== "undefined" ? localStorage.getItem("username") : "") || "";
  const lng = (typeof window !== "undefined" ? localStorage.getItem("i18nextLng") : "") || "ko";
  const constrId = (typeof window !== "undefined" ? localStorage.getItem("constr_id") : "") || "";
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const isNotifyingRef = useRef(false);
  const queryClient = useQueryClient();

  const { mutate: logout, isSuccess: logoutSuccess } = usePostLogout();
  const { handleLngOptionClick, lngLabel, logoutLabel, mypageLabel } = useHeader({ lng });
  const { data: notiListRaw, refetch } = useGetNotificationList(constrId);
  const { setStartDate } = useReportSearchStore();

  const hideHeaderRoutes = ["/en", "/ko", "/en/RegisterConstr", "/ko/RegisterConstr", "/ko/Dashboard/edit"];

  const { mutate: triggerNotify } = usePostNotify();

  const checkMidnight = () => {
    const now = new Date();
    const today = now.toLocaleDateString("ko-KR", { timeZone: "Asia/Seoul" });
    const lastNotifyDate = typeof window !== "undefined" ? localStorage.getItem("lastNotifyDate") : "";
    const localHours = now.getHours();
    const localMinutes = now.getMinutes();
    const isMidnightHour = localHours === 0 && localMinutes < 5;

    if (isMidnightHour && today !== lastNotifyDate && constrId && !isNotifyingRef.current) {
      isNotifyingRef.current = true;
      triggerNotify(constrId, {
        onSuccess: () => {
          typeof window !== "undefined" ? localStorage.setItem("lastNotifyDate", today) : "";
          refetch();
          isNotifyingRef.current = false;
        },
        onError: (error) => {
          isNotifyingRef.current = false;
        },
      });
    }
  };

  useEffect(() => {
    if (!constrId) {
      return;
    }

    checkMidnight();

    intervalRef.current = setInterval(() => {
      checkMidnight();
    }, 60 * 1000);

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [constrId, triggerNotify, refetch]);

  const handleLogout = () => {
    const refreshToken = typeof window !== "undefined" ? localStorage.getItem("refresh_token") : "";
    const formData = new FormData();
    formData.append("refresh", refreshToken || "");
    logout(formData);
  };

  const handleClickMypage = () => {
    router.push(`/${lng}/MyPage`);
  };

  useEffect(() => {
    if (logoutSuccess) {
      console.log("Logout successful, redirecting to /");
      router.push("/");
    }
  }, [logoutSuccess, router]);

  const handleNotificationClick = (dateString: string) => {
    const selectedDate = new Date(dateString);
    setStartDate(selectedDate);
    router.push(`/${lng}/Sitelog/WriteReport`);
  };

  return (
    <>
      {!hideHeaderRoutes.includes(pathname) && (
        <UpsightHeader
          title={t(pathnameArray[2])}
          author={username}
          logoutlabel={logoutLabel}
          currentLng={lngLabel}
          mypagelabel={mypageLabel}
          handleClickLogout={handleLogout}
          handleClickMypage={handleClickMypage}
          onLanChange={handleLngOptionClick}
          notifications={notiListRaw}
          construction={constrId ?? ""}
          onNotificationClick={handleNotificationClick}
        />
      )}
    </>
  );
};

export default Header;
