import { useMutation, useQueryClient } from "@tanstack/react-query";

import { NotificationApi } from "@/utils/fetchData";

const postNotify = async (constrId: string) => {
  const response = await NotificationApi.postNotify(constrId);
  return response.data;
};

export const usePostNotify = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (constrId: string) => postNotify(constrId),
    onSuccess: (_, constrId) => {
      // POST 후 캐시 무효화 없이 refetch에 의존
      console.log("POST notify successful for constrId:", constrId);
    },
    onError: (error) => {
      console.error("usePostNotify error:", error);
    },
  });
};
