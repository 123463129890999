import { useMutation, useQueryClient } from "@tanstack/react-query";

import { AuthApi } from "@/utils/fetchData";

const postLogout = async (formData: FormData) => {
  const response = await AuthApi.postLogout(formData);
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userid");
  localStorage.removeItem("username");

  return response;
};

export const usePostLogout = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postLogout,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["logout"] });
    },
  });
};
